import React from "react";
import "./Header.css";
const Header = () => {
  return (
    <div className="Header">
        <a href="https://hansmatrimony.com">
          <img
            className="image"
            src="images/logo.png"
            alt="Company"
            style={{ width: '250%' , verticalAlign: "middle" }}
          />
        </a>
      <h3>Join Whatsapp Group</h3>
    </div>
  );
};

export default Header;
