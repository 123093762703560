import React from "react";
import { useEffect } from "react";
import Header from "../components/Header.js";
import Form from "../components/Form.js";

export default function Home() {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.defer = true;
  //   script.src = "https://www.googletagmanager.com/gtag/js?id=AW-682592773";
  //   document.head.appendChild(script);

  //   const inlineScript = document.createElement("script");
  //   inlineScript.defer = true;
  //   inlineScript.innerHTML = `
  //             window.dataLayer = window.dataLayer || [];
  //              window.dataLayer = window.dataLayer || [];
  //   function gtag(){dataLayer.push(arguments);}
  //   gtag('js', new Date());
  
  //   gtag('config', 'AW-682592773');
  //         `;
  //   document.head.appendChild(inlineScript);

  //   return () => {
  //     document.head.removeChild(script);
  //     document.head.removeChild(inlineScript);
  //   };
  // }, []);
  return (
    <div
      className="col-11 col-xl-4 col-lg-5 col-md-8 mx-auto mt-3 hansFormMainContainer flower-border pb-4"
      style={{
        maxWidth: "500px",
        minHeight: "300px",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Header />
      <Form />
    </div>
  );
}
