import React from 'react'
import Thankyou from '../components/Thankyou'
import Header from '../components/Header'

function ThankyouPage() {
  return (
    <div
      className="col-11 col-xl-4 col-lg-5 col-md-8 mx-auto mt-3 hansFormMainContainer flower-border pb-4"
      style={{
        maxWidth: "500px",
        minHeight: "300px",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Header/>
      <Thankyou />
    </div>
  )
}

export default ThankyouPage;
