import React from "react";
import "./App.css"; // Import your main CSS file if you have one
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Optional for Bootstrap's JS functionality
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.page";
import ThankyouPage from "./pages/Thakyou.page";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thankyou" element={<ThankyouPage />} />
      </Routes>
    </Router>
  );
}
export default App;
