import React, { useState } from "react";
import { useEffect } from "react";
import "./Form.css";

function Thankyou() {
  const [groupDetails, setgropDetails] = useState({});
  useEffect(() => {
    const gdetails = JSON.parse(localStorage.getItem("user-group-details"));
    setgropDetails(gdetails);
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11521047247";
    document.head.appendChild(script);
    // console.log(gdetails.familyIncome);
    const gtaginlineScript = document.createElement("script");
    gtaginlineScript.async = true;
    gtaginlineScript.innerHTML = `
              window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-11521047247');
          `;
    document.head.appendChild(gtaginlineScript);
    if (!gdetails.familyIncome) {
      console.log(gdetails);
      const inlineScript = document.createElement("script");
      inlineScript.defer = true;
      inlineScript.innerHTML = `
            gtag('event', 'conversion', {
              'send_to': 'AW-11521047247/8NjvCLjTzf8ZEM_11PUq',
              'value': 1.0,
              'currency': 'INR'
              });
              `;
      document.head.appendChild(inlineScript);
    }
  }, []);
  return (
    <div className="mt-3 text-justify" style={{ style: "100vh" }}>
      <p>
        Thanks, you're all set. Our representative will get in touch with you
        soon. You can also call at{" "}
        <a href="tel:+91-9697989697">+91-9697989697</a>
      </p>
      <h3 className="text-center">Based on your preferences:</h3>
      <p className="text-center">{groupDetails.title}</p>
      {groupDetails.link && (
        <p className="text-center">
          Join our WhatsApp group here:{" "}
          <a
            href={groupDetails.link}
            className="btn btn-success"
            target="_blank"
            rel="noopener noreferrer"
          >
            {groupDetails.title}
          </a>
        </p>
      )}
    </div>
  );
}

export default Thankyou;
