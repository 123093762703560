import React, { useState, useEffect } from "react";
import "./Form.css";

const Form = () => {
  const [formData, setFormData] = useState({
    communityPreference: "",
    lookingFor: "",
    fullName: "",
    familyIncome: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({});
  // const [submitted, setSubmitted] = useState(false);
  const [redirectLink, setRedirectLink] = useState(null);

  const groupLinksWithTitles = {
    "Punjabi-boy": {
      title: "Punjabi Boy WhatsApp Group",
      link: "https://chat.whatsapp.com/ITiqTLJdiCg6nRW5Bz36lH",
    },
    "Punjabi-girl": {
      title: "Punjabi Girl WhatsApp Group",
      link: "https://chat.whatsapp.com/DFN8VLQMf1y4xDOV9Gkbn0",
    },
    "Baniya-boy": {
      title: "Baniya Boy WhatsApp Group",
      link: "https://chat.whatsapp.com/IqvfKqG1UqEGz2Ez6aYrLt",
    },
    "Baniya-girl": {
      title: "Baniya Girl WhatsApp Group",
      link: "https://chat.whatsapp.com/GX5Urp3AxbX8AlosRMN3eR",
    },
    "NRI-boy": {
      title: "NRI Boy Rishtey WhatsApp Group",
      link: "https://chat.whatsapp.com/EjiwueKa7zCGQOV0PRCxBT",
    },
    "NRI-girl": {
      title: "NRI Girls Rishtey WhatsApp Group",
      link: "https://chat.whatsapp.com/BndNR5gAKr87IOoZycFflS",
    },
    "Divorced-boy": {
      title: "Divorce Boy Rishtey WhatsApp Group",
      link: "https://chat.whatsapp.com/Lc2ZgUzEH8JGlnmqgQvmzQ",
    },
    "Divorced-girl": {
      title: "Divorce Girls Rishtey WhatsApp Group",
      link: "https://chat.whatsapp.com/GxyGxxLr4AYDQ0fjzlxjHn",
    },
    "default-girl": {
      title: "All Community Girl WhatsApp Group",
      link: "https://chat.whatsapp.com/CwczQ3PFNfC5sF7gEmhia1",
    },
    "default-boy": {
      title: "All Community Boy WhatsApp Group",
      link: "https://chat.whatsapp.com/DMl7xxvTbWdAmLS4JdAfWF",
    },
    "0-10": {
      title: "join WhatsApp Group",
      link: "https://chat.whatsapp.com/BVx6R1ZQzfu28W8lUo9hiD",
      familyIncome:"0-10"
    },
  };

  // Validate Form Fields
  const validateFields = () => {
    const newErrors = {};
    if (!formData.communityPreference) {
      newErrors.communityPreference = "Community preference is required.";
    }
    if (!formData.lookingFor) {
      newErrors.lookingFor = "Please select 'Looking for Rishtey for'.";
    }
    if (!formData.familyIncome) {
      newErrors.familyIncome = "Please select 'Family income'.";
    }
    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full name is required.";
    } else {
      const nameRegex = /^[A-Za-z\s]+$/;
      if (!nameRegex.test(formData.fullName)) {
        newErrors.fullName = "Name should only contain alphabetic characters.";
      }
    }
    if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be 10 digits.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Form Submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    try {
      const response = await fetch(
        "https://backend.hansmatrimony.com/api/v1/save-incomplete-leads",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // setSubmitted(true);
        const groupDetails = getGroupDetails();
        localStorage.setItem(
          "user-group-details",
          JSON.stringify(groupDetails)
        );
        setRedirectLink(groupDetails.link); // Set the WhatsApp link for redirection
      } else {
        console.error("Failed to save lead");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Generate Group Details Based on User's Selection
  const getGroupDetails = () => {
    const { communityPreference, lookingFor, familyIncome } = formData;
    if (familyIncome === "0-10") {
      return groupLinksWithTitles["0-10"];
    }
    const groupKey = `${communityPreference}-${lookingFor}`;
    const groupKey2 = `default-${lookingFor}`;
    return groupLinksWithTitles[groupKey] || groupLinksWithTitles[groupKey2];
  };

  // Automatically open WhatsApp link in a new tab after form submission
  useEffect(() => {
    if (redirectLink) {
      window.open(redirectLink, "_blank");
      window.location.href = "/thankyou";
    }
  }, [redirectLink]);

  // if (submitted) {
  //   window.location.href = "/thankyou";
  // }

  // Render Form
  return (
    <form className="form" onSubmit={handleFormSubmit}>
      <label className="form-label">
        Belongs to?:
        {errors.communityPreference && (
          <span className="error">{errors.communityPreference}</span>
        )}
        <select
          className="form-select"
          name="communityPreference"
          value={formData.communityPreference}
          onChange={handleInputChange}
        >
          <option value="">Select Option</option>
          <option value="Punjabi">Punjabi</option>
          <option value="Baniya">Baniya</option>
          <option value="Brahmin">Brahmin</option>
          <option value="Sikh">Sikh</option>
          <option value="NRI">NRI</option>
          <option value="Divorced">Divorced</option>
          <option value="Other">Other</option>
        </select>
      </label>
      <br />
      <label className="form-label">
        Looking for Rishtey for:
        {errors.lookingFor && (
          <span className="error">{errors.lookingFor}</span>
        )}
        <select
          name="lookingFor"
          className="form-select"
          value={formData.lookingFor}
          onChange={handleInputChange}
        >
          <option value="">Select</option>
          <option value="boy">Boy</option>
          <option value="girl">Girl</option>
        </select>
      </label>
      <br />
      <label className="form-label">
        Family income Anually :
        {errors.familyincome && (
          <span className="error">{errors.familyincome}</span>
        )}
        <select
          name="familyIncome"
          className="form-select"
          value={formData.familyIncome}
          onChange={handleInputChange}
        >
          <option value="">Select</option>
          <option value="0-10">0 - 10 Lakh</option>
          <option value="10-50">10 - 50 Lakh</option>
          <option value="50-1">50 Lakh - 1Cr.</option>
          <option value="1">1 Cr. +</option>
        </select>
      </label>
      <br />
      <label>
        Full Name:
        {errors.fullName && <span className="error">{errors.fullName}</span>}
        <input
          type="text"
          name="fullName"
          className="form-control"
          placeholder="Full Name"
          value={formData.fullName}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <label>
        Phone Number:
        {errors.phoneNumber && (
          <span className="error">{errors.phoneNumber}</span>
        )}
        <input
          type="text"
          name="phoneNumber"
          className="form-control"
          placeholder="Mobile Number"
          value={formData.phoneNumber}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <button className="btn btn-primary" type="submit">
        Join Whatsapp Group
      </button>
    </form>
  );
};

export default Form;
